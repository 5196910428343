import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Scrollbar } from 'react-scrollbars-custom'
import * as styles from './ScrollList.styles.scss'

const ScrollList = ({ children, style }) => {
  const shadowTop = useRef()
  const shadowBottom = useRef()

  const containerStyle = {
    ...style,
    position: 'relative',
    width: '100%',
  }

  const handleUpdate = ({ scrollTop, scrollHeight, clientHeight }) => {
    const shadowTopOpacity = (1 / 20) * Math.min(scrollTop, 20)
    const bottomScrollTop = scrollHeight - clientHeight
    const shadowBottomOpacity =
      (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20))
    shadowTop.current.style.opacity = shadowTopOpacity
    shadowBottom.current.style.opacity = shadowBottomOpacity
  }

  return (
    <div className={styles.ScrollList} style={containerStyle}>
      <Scrollbar onUpdate={handleUpdate}>
        <div className={styles.inner}>{children}</div>
      </Scrollbar>
      <div className={styles.shadowTop} ref={shadowTop} />
      <div className={styles.shadowBottom} ref={shadowBottom} />
    </div>
  )
}

ScrollList.defaultProps = {
  children: undefined,
  style: undefined,
}

ScrollList.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
}

export { ScrollList }
