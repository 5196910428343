import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { get } from '@/utils/get'
import { getImage } from '@/prismic/utils/getImage'
import { getRichText } from '@/prismic/utils/getRichText'
import { useSiteConfig } from '@/prismic/hooks/useSiteConfig'
import Home from '@/templates/Home'

const HomePrismic = ({ data }) => {
  const { prismicHome } = data

  const events = []
  prismicHome.data.upcoming.forEach((item, index) => {
    events.push({
      id: `upcoming-${index}`,
      title: item.title,
      date: item.date,
      description: getRichText(item, 'description.raw'),
      link: get(item, 'link.url')
        ? {
            url: item.link.url,
            target: item.link.target,
          }
        : undefined,
    })
  })

  const heroSlides = []
  prismicHome.data.hero_slides.forEach((item, index) => {
    const image = getImage(item, 'image', 'fluid')
    heroSlides.push({
      id: `hero-${index}`,
      image,
      imageMobile: getImage(item, 'image', 'fixed'),
    })
  })

  const siteConfig = useSiteConfig()

  return (
    <Home
      title={get(prismicHome, 'data.title')}
      heroSlides={heroSlides}
      intro={getRichText(prismicHome, 'data.intro.raw')}
      events={events}
      nav={siteConfig.nav}
    />
  )
}

HomePrismic.defaultProps = {
  data: {},
}

HomePrismic.propTypes = {
  data: PropTypes.object,
}

export default withPreview(HomePrismic)

export const pageQuery = graphql`
  query HomeByID($id: String!) {
    prismicHome(id: { eq: $id }) {
      uid
      type
      data {
        title
        intro {
          raw
        }
        hero_slides {
          image {
            fluid(maxWidth: 2500) {
              ...GatsbyPrismicImageFluid_noBase64
            }
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        upcoming {
          title
          date(formatString: "DD MMM YYYY", locale: "en-GB")
          description {
            raw
          }
          link {
            url
            target
          }
        }
      }
    }
  }
`
