import React from 'react'
import PropTypes from 'prop-types'
import { get } from '@/utils/get'
import { Container } from '@/components/Container'
import { ScrollList } from '@/components/ScrollList'
import { Menu } from '@/components/Menu'
import { Link } from '@/components/Link'
import { Hero } from '@/sections/Hero'
import * as styles from './Home.styles.scss'

const HomeTemplate = ({ intro, heroSlides, events, nav }) => {
  return (
    <div>
      <Hero slides={heroSlides} prev="none" />
      <Container>
        <div className={styles.main}>
          <div className={styles.intro}>{intro}</div>
          {!!events.length && (
            <div className={styles.events}>
              <h3 className={styles.events__heading}>Upcoming</h3>
              <ScrollList>
                <ul className={styles.events__list}>
                  {events.map((event) => {
                    const eventTitle = `${event.title} – ${event.date}`
                    return (
                      <li key={event.id} className={styles.events__item}>
                        <h4 className={styles.events__title}>
                          {get(event, 'link.url') ? (
                            <Link
                              to={event.link.url}
                              target={event.link.target}
                            >
                              {eventTitle}
                            </Link>
                          ) : (
                            eventTitle
                          )}
                        </h4>
                        <div>{event.description}</div>
                      </li>
                    )
                  })}
                </ul>
              </ScrollList>
            </div>
          )}
        </div>
      </Container>

      <Menu nav={nav} isModal={false} />
    </div>
  )
}

HomeTemplate.defaultProps = {
  intro: undefined,
  heroSlides: [],
  events: [],
  nav: [],
}

HomeTemplate.propTypes = {
  intro: PropTypes.node,
  heroSlides: PropTypes.arrayOf(PropTypes.object),
  events: PropTypes.arrayOf(PropTypes.object),
  nav: PropTypes.arrayOf(PropTypes.object),
}

export default HomeTemplate
